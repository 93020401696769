import React, { useState, useCallback, useEffect, Fragment } from "react";

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import 'bootstrap/dist/css/bootstrap.css';
import {useLazyQuery, useMutation} from "@apollo/react-hooks";
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { Form, Input, Button, Checkbox } from 'antd';

import LoadingOverlay from 'react-loading-overlay'
import NavigationBar from './NavigationBar';
import Header from './Header';
import Footer from './Footer';
import queryString from 'query-string';
import { useTranslation } from "react-i18next";
import {RewardTypes, SystemStatus} from "../utils/constants";

const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};
const Styles = styled.div`
.bg-SunPowerBlue {
	background-color: #0076be;
}
.payment-radio {
    margin-left: 150px;
}
.form-check-label {
    text-align: center important!;
}
.ant-radio-button {
    padding: 10px;
}
.OrangeBtn {
    margin-left: 400px;
	font-weight: 500;
	background-color: #F18B21;
	color: hsla(0,0%,100%,1.00);
}
.OrangeBtn:hover {
    
	background-color: #E2620E;
	color: hsla(0,0%,100%,1.00);
}
.OrangeBtn2 {
    font-weight: 500;
	background-color: #F18B21;
	color: hsla(0,0%,100%,1.00);
}
.OrangeBtn2:hover {
	background-color: #E2620E;
	color: hsla(0,0%,100%,1.00);
}
.BlueBtn {
	font-weight: 500;
	background-color: #0076BE;
	color: #FFFFFF;
}

.BlueBtn:hover {
	background-color:#39607A;
	color: #FFFFFF;
}

.BlueBtn:active {
	background-color:#39607A;
	color: #FFFFFF;
}
.BlueBtn:focus {
	background-color:#39607A;
	color: #FFFFFF;
}


.BlueBtnOutline {
	font-weight: 500;
	border: 1px solid #0076BE;
    background-color:#FFFFFF !important;
    color: #0076BE;
}

.BlueBtnOutline:hover {
	background-color:#0076BE !important;
	color: #FFFFFF;
}

.BlueBtnOutline:focus {
	background-color:#39607A !important;
	color: #FFFFFF;
}

.BlueBtnOutline:active {
	background-color:#39607A !important;
	color: #FFFFFF;
}

.BlueRadioBtn:hover {
	border: 1px solid #0076BE;
	color: #0076BE;
}

.BlueRadioBtn.active {
	border: 1px solid #0076BE;
	color: #0076BE;
}

main {
	min-height: 50vh;
	padding-bottom: 90px;
}

footer{
	height: 80px;
	margin-top: -80px;
	width: 100%;
}
.errorMsg {
    color: red;
}

.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

.divider-light {
	background-color: #E0E0E5;
	height: 2px;
	margin-bottom: 14px;
}

.divider-orange {
	background-color: #F18B21;
	height: 3.5px;
	width: 36px;
}

.divider-orange-lg {
	background-color: #F18B21;
	height: 3px;
	width: 100px;
}


.bg-banner1 {
	background-image: url("images/1200x627_banner no logo (1).png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom;
}

.bg-SunPowerBlack {
	background-color: hsla(0,0%,0%,0.60);
	color:#FFFFFF;
	text-align: center;
}



/*Nav*/
.nav-link.active {
	color: #F18B21;
	border-bottom: #F18B21 2px solid;
}

.nav-link:hover {
	color: #F18B21;
	border-bottom: #F18B21 2px solid;
}
/*Nav*/

/*Tabs*/

.nav-link.tab-title {
	color: #858585;
	background-color: #EEEEEE;
}

.nav-link.tab-title:hover {
	color: #E2620E;
}

.nav-link.tab-title.active {
	color: #E2620E;
}

/*Tabs*/


/*Links*/

.OrangeLink {
	color: #F18B21;
}
.OrangeLink:hover {
	color: #E2620E;
}

.BlueLink {
	color: #0076BE;
}

/*Typography*/

h1, h2, h3, h4, h5 {
	font-weight: 500;
}
`;

const query = gql`
    query TrackPayment($token: String) {
        trackPayment(token:$token) {
            status
            fundingDate
            fundingStatus
            fullName
            paymentType
            amount
            cardType
            shipDate
            actualCardType
        }
}
`;
const TrackPayment = (props) => {
    const { t } = useTranslation();
    const queryValues = queryString.parse(props.location.search);
    const [paymentStatus, setPaymentStatus] = useState({message:'', message1: '', message2: '', message3: '', message4: ''})
    const [tracker, { loading, data }] = useLazyQuery(query,{
        fetchPolicy:'network-only'
    });
    const [showSpinner, setShowSpinner] = useState({display: false})
    const [showTracker, setShowTracker] = useState(false)
    const onFinish = values => {
        console.log('Success:', values);
        setShowSpinner({display: true});
        tracker({
            variables: {
                token: values.trackingNo
            }
            });
    };

    useEffect(() => {
        if (data && !loading && showSpinner.display) {
            //console.log(data);
            const processingStatus = [SystemStatus.REDEMMED, SystemStatus.PROCESSING, SystemStatus.PROCESSED, SystemStatus.AUTO_REDEEMED, SystemStatus.FAILED]
            const message1 = t('track_name')+': '+data.trackPayment.fullName;
            const message2 = t('track_payment_type')+': '+data.trackPayment.paymentType;
            const message3 = t('track_amount')+': $'+data.trackPayment.amount;
            const message4 = t('track_reward_type')+': '+data.trackPayment.cardType;
            const shipDate = data.trackPayment.shipDate;
            const responseStatus = data.trackPayment.status
            console.log('responseStatus: ', responseStatus);
            if (responseStatus === 'Wrong token') {
                setPaymentStatus({message: t('track_number_no_info')})
            } else if(responseStatus === SystemStatus.EXPIRED){
                setPaymentStatus({message: t('payment_expired'), message1, message2, message3, message4})
            } else if(responseStatus === SystemStatus.REDEEMED_FOR_CHECK){
                setPaymentStatus({message: t('redeemed_for_check'), message1, message2, message3, message4})
            } else if(responseStatus === SystemStatus.VOID){
                setPaymentStatus({message: t('voided'), message1, message2, message3, message4})
            } else if(processingStatus.includes(responseStatus)){
                if (data.trackPayment.actualCardType === RewardTypes.VIRTUAL) {
                    setPaymentStatus({message: t('processing_status_mean_virtual'), message1, message2, message3, message4})
                }else {
                    setPaymentStatus({message: t('processing_status_mean'), message1, message2, message3, message4})
                }
            } else if(responseStatus === SystemStatus.SHIPPED){
                setPaymentStatus({message: t('the')+' '+data.trackPayment.cardType+' '+t('track_has_been_shipped_on')+' : '+shipDate, message1, message2, message3, message4})
            } else if (responseStatus === SystemStatus.PENDING || data.trackPayment.fundingStatus === 'Pending Mailing Confirmation') {
                switch (data.trackPayment.actualCardType){
                    case RewardTypes.VIRTUAL:
                        setPaymentStatus({message: t('the')+' '+data.trackPayment.cardType+' '+t('virtual_card_sent_out'), message1, message2, message3, message4})
                        break;
                    case RewardTypes.CHECK:
                        setPaymentStatus({message: t('the')+' '+data.trackPayment.cardType+' '+t('check_card_sent_out'), message1, message2, message3, message4})
                        break;
                    default:
                        setPaymentStatus({message: t('the')+' '+data.trackPayment.cardType+' '+t('is_pending_mailing_confirm'), message1, message2, message3, message4})
                        break;
    
                }
            } else if (shipDate.length > 0) {
                setPaymentStatus({message: t('the')+' '+data.trackPayment.cardType+' '+t('track_has_been_shipped_on')+' : '+shipDate, message1, message2, message3, message4})
            } else if (responseStatus === 'success' && data.trackPayment.fundingStatus === 'pending' ) {
                setPaymentStatus({message: t('track_payment_pending_status'), message1, message2, message3, message4})
            } else if (responseStatus === 'success' && data.trackPayment.fundingStatus === 'processing'  && data.trackPayment.fundingDate) {
                setPaymentStatus({message: t('track_payment_processed')+data.trackPayment.fundingDate, message1, message2, message3, message4})
            } else if (responseStatus === 'success' && data.trackPayment.fundingStatus === 'processing') {
                setPaymentStatus({message: t('track_payment_tobe_processed'), message1, message2, message3, message4})
            }
            setShowSpinner({display: false});
        }
    },[data])

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    

    return (
        <Fragment>
            <LoadingOverlay
                active={showSpinner.display}
                spinner
                text={t("loading_content")}
                >
                <NavigationBar />
                <Header 
                    showTracker={showTracker}
                />
                <Styles>
                    <main>
                    <div className="container border p-5">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <p className="font-weight-bold border-bottom">{t("tracking_no")}:</p>
                                <Form
                                    name="basic"
                                    initialValues={{ trackingNo: queryValues.trackingNo }}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    >
                                    <Form.Item
                                        name="trackingNo"
                                        rules={[{ required: true, message: t("tracking_no_error_message") }]}
                                    >
                                        <Input placeholder={t('enter_tracking_no')} />
                                    </Form.Item>
                                    <Form.Item {...tailLayout}>
                                        <Button type="primary" htmlType="submit">
                                        {t('track_now')}
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                            <div className="col-6">
                                <p className="font-weight-bold border-bottom">{t('current_status')}:</p>
                                <p>{paymentStatus.message}</p>
                                <p>{paymentStatus.message1}</p>
                                <p>{paymentStatus.message2}</p>
                                <p>{paymentStatus.message3}</p>
                                <p>{paymentStatus.message4}</p>
                            </div>
                        </div>
                    </div>
                    </main>
                </Styles>
                <Footer />
            </LoadingOverlay>
        </Fragment>
        
    );
}

export default TrackPayment;