import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";

const Styles = styled.div`
  .navbar {
        background-color: #fff;
    }

    a, .navbar-brand, .navbar-nav .nav-link {
        color: #6c757d;

        &:hover {
            color: hsla(0,0%,0%,1.00);
	        border-bottom: #F18B21 2px solid;
        }
        &:active {
            color: hsla(0,0%,0%,1.00);
	        border-bottom: #F18B21 2px solid;
        }
    }

    .nav-link.tab-title {
            color: #858585;
            background-color: #EEEEEE;
        }

        .nav-link.tab-title:hover {
            color: #E2620E;
        }

        .nav-link.tab-title.active {
            color: #E2620E;
        }
`;

const NavigationBar = props => {
    const { t } = useTranslation();
    return (
        <Styles>
            <Navbar expand="lg">
            <Navbar.Brand href="/"><img src="images/SunPower.png" width="180px" alt="logo"/></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="justify-content-center mx-auto">
                    <Nav.Item>
                        <Nav.Link href="/track">{t('track_payment_nav')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/contactus">{t('contact_us_nav')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/faqs">{t('faq_nav')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/cardbalance">{t('card_bal_nav')}</Nav.Link>
                    </Nav.Item>
                </Nav>
            </Navbar.Collapse>
            </Navbar>
        </Styles >
    );
};

export default NavigationBar;
