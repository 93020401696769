import React, {Fragment} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { Image } from 'antd';
import 'antd/dist/antd.css';
import'./PeguPayments-style.css';
import { useTranslation } from "react-i18next";

const CardBalanceContent = () => {
    const { t } = useTranslation();
    return (
        <Container className="pb-5 vh-fill">
            <Row>
                <Col>
                    <h2>{t('card_bal_header')}</h2>
                    <div className="divider-orange my-4" />
                    <h5 className="display-6">{t('check_url_back_of_card')}</h5>
                    <p className="mb-0">{t('follow_url_back_visa_card')}</p>
                </Col>
            </Row>
            <Row className="pt-5 text-center justify-content-center mb-5">
                <Col lg={3}>
                    <Image src="images/Card Balance - BPSmyaccount.png" alt="Card sample" width="200px" className="d-block" />
                    <p><a href="https://bpsmyaccount.com" className="BlueLink fs-24">BPSmyaccount.com</a></p>
                </Col>
                <Col lg={3}>
                    <Image src="images/bpscardaccount.png" alt="Card sample" width="200px" className="d-block" />
                    <p><a href="https://bpscardaccount.com" className="BlueLink fs-24">BPScardaccount.com</a></p>
                </Col>
            </Row>
        </Container>
    );
}
export default CardBalanceContent;
