import React, { useState, Fragment, useEffect } from "react";
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import 'bootstrap/dist/css/bootstrap.css';
import { useQuery, useMutation } from "@apollo/react-hooks";
import styled from 'styled-components';
import 'antd/dist/antd.css';
import {
    Form,
    Radio,
    Button,
    Row,
    Col
} from 'antd';
import queryString from 'query-string'
import LoadingOverlay from 'react-loading-overlay'
import MailingModal from './MailingModal';
import { useTranslation } from "react-i18next";

const Styles = styled.div`
.bg-SunPowerBlue {
	background-color: #0076be;
}

.ant-radio-group {
    width: 100%;
}
.col-centered {
    float: none;
    margin-right: auto;
    margin-left: auto;
}
.form-check-label {
    text-align: center important!;
}
.ant-radio-button {
    padding: 10px;
}
.spacing {
    width: 10px;
}
@media only screen and (min-width: 800px) {
    .OrangeBtn {
        margin-left: 400px;
        font-weight: 500;
        background-color: #F18B21;
        color: hsla(0,0%,100%,1.00);
    }
}
@media only screen and (max-width: 600px) {
    .OrangeBtn {
        margin-left: 100px;
        font-weight: 500;
        background-color: #F18B21;
        color: hsla(0,0%,100%,1.00);
    }
}
.OrangeBtn:hover {
    
	background-color: #E2620E;
	color: hsla(0,0%,100%,1.00);
}
.OrangeBtn2 {
    font-weight: 500;
	background-color: #F18B21;
	color: hsla(0,0%,100%,1.00);
}
.OrangeBtn2:hover {
	background-color: #E2620E;
	color: hsla(0,0%,100%,1.00);
}
.BlueBtn {
	font-weight: 500;
	background-color: #0076BE;
	color: #FFFFFF;
}

.BlueBtn:hover {
	background-color:#39607A;
	color: #FFFFFF;
}

.BlueBtn:active {
	background-color:#39607A;
	color: #FFFFFF;
}
.BlueBtn:focus {
	background-color:#39607A;
	color: #FFFFFF;
}


.BlueBtnOutline {
	font-weight: 500;
	border: 1px solid #0076BE;
    background-color:#FFFFFF !important;
    color: #0076BE;
}

.BlueBtnOutline:hover {
	background-color:#0076BE !important;
	color: #FFFFFF;
}

.BlueBtnOutline:focus {
	background-color:#39607A !important;
	color: #FFFFFF;
}

.BlueBtnOutline:active {
	background-color:#39607A !important;
	color: #FFFFFF;
}

.BlueRadioBtn:hover {
	border: 1px solid #0076BE;
	color: #0076BE;
}

.BlueRadioBtn.active {
	border: 1px solid #0076BE;
	color: #0076BE;
}

main {
	min-height: 100vh;
	padding-bottom: 90px;
}

footer{
	height: 80px;
	margin-top: -80px;
	width: 100%;
}
.errorMsg {
    color: red;
}

.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

.divider-light {
	background-color: #E0E0E5;
	height: 2px;
	margin-bottom: 14px;
}

.divider-orange {
	background-color: #F18B21;
	height: 3.5px;
	width: 36px;
}

.divider-orange-lg {
	background-color: #F18B21;
	height: 3px;
	width: 100px;
}


.bg-banner1 {
	background-image: url("images/1200x627_banner no logo (1).png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom;
}

.bg-SunPowerBlack {
	background-color: hsla(0,0%,0%,0.60);
	color:#FFFFFF;
	text-align: center;
}



/*Nav*/
.nav-link.active {
	color: #F18B21;
	border-bottom: #F18B21 2px solid;
}

.nav-link:hover {
	color: #F18B21;
	border-bottom: #F18B21 2px solid;
}
/*Nav*/

/*Tabs*/

.nav-link.tab-title {
	color: #858585;
	background-color: #EEEEEE;
}

.nav-link.tab-title:hover {
	color: #E2620E;
}

.nav-link.tab-title.active {
	color: #E2620E;
}

/*Tabs*/


/*Links*/

.OrangeLink {
	color: #F18B21;
}
.OrangeLink:hover {
	color: #E2620E;
}

.BlueLink {
	color: #0076BE;
}

/*Typography*/

h1, h2, h3, h4, h5 {
	font-weight: 500;
}
`;

const query = gql`
  query PaymentsRequest($token: String!){
    paymentsRequest(token: $token) {
    paymentType
    amount
    cusLeaseNo
    guaranteeYr
    fullName
    email
    phone
    status
    address {
      address1
      address2
      city
      state
      zip
      addressType
    }
  }
  }
`;

const insertMutation = gql`
    mutation PaymentTypeSelection($address1: String, $address2: String, $city: String, $state: String, $zip:String, $token:String,$cardType:String, $ownHome: Boolean, $addrChangeReason: String) {
        paymentTypeSelection(input: {address1: $address1, address2: $address2, city: $city, state: $state, zip:$zip, token:$token,cardType:$cardType, ownHome:$ownHome, addrChangeReason: $addrChangeReason}) {
            status
            trackingNo
        }
}
`;
const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 14,
    },
};


const PeguPayment = (props) => {
    //console.log(props);
    const { t } = useTranslation();
    const values = queryString.parse(props.location.search);
    //console.log(values);
    let { loading, data, error } = useQuery(query, {
        variables:
            { token: values.token }
    });

    const [displayFields, setDisplayFields] = useState([
        { paymentType: '', amount: 0, cusLeaseNo: '', guaranteeYr: '', fullName: '', email: '', phone: '', address: [], status: '', __typename: '' }
    ]);
    const [displayInstallAddress, setInstallAddress] = useState({ address1: '', address2: '', city: '', state: '', zip: '' });
    const [displayMailingAddress, setMailingAddress] = useState({ address1: '', address2: '', city: '', state: '', zip: '' });
    const [showSpinner, setShowSpinner] = useState({ display: true })
    const [showCardError, setCardError] = useState(false)
    const [showMailingError, setMailingError] = useState(false)
    const [ownHomeStatus, setOwnHomeStatus] = useState(true);
    const [showPhysicalCardRewardType, setShowPhysicalCardRewardType] = useState(true);
    const [showVirtualCardRewardType, setShowVirtualCardRewardType] = useState(true);
    const [showCheckRewardType, setShowCheckRewardType] = useState(false);

    useEffect(() => {

        if (loading) console.log(loading);
        console.log('showConfirmButtons: ', showConfirmButtons)
        if (!loading && !error && showSpinner.display && !showConfirmButtons) {
            //console.log('data: ',data);
            if (parseFloat(data.paymentsRequest.amount) > 1000) {
                setShowVirtualCardRewardType(false);
            }
            if (parseFloat(data.paymentsRequest.amount) > 2500) {
                setShowPhysicalCardRewardType(false);
            }
            if (parseFloat(data.paymentsRequest.amount) > 500) {
                setShowCheckRewardType(true);
            }
            data.paymentsRequest.amount = '$' + data.paymentsRequest.amount;
            setDisplayFields(data.paymentsRequest);
            setShowSpinner({ display: false });
            data.paymentsRequest.address.map(paddress => {
                if (paddress['addressType'] == 'install') {
                    const iAddress = {
                        address1: paddress['address1'], address2: paddress['address2'], city: paddress['city'],
                        state: paddress['state'], zip: paddress['zip']
                    };
                    setInstallAddress(iAddress);
                } else {
                    const mAddress = {
                        address1: paddress['address1'], address2: paddress['address2'], city: paddress['city'],
                        state: paddress['state'], zip: paddress['zip']
                    };
                    setMailingAddress(mAddress);
                }
            });
        } else if (!loading) {
            if (data.paymentsRequest.status === 'This token has already been redeemed and cannot be redeemed again') {
                window.location = '/tokenredeemedpegu';
            }
        }
    })

    const onFinish = values => {
        console.log('Received values of form: ', values);
    };

    const [visibleModal, setModalVisible] = useState(false);
    //const [showPaymentSelection, setshowPaymentSelection] = useState(true);
    const [showPhysicalCardSelection, setshowPhysicalCardSelection] = useState(false);
    const [showVirtualCardSelection, setshowVirtualCardSelection] = useState(false);
    const [showCheckSelection, setshowCheckSelection] = useState(false);
    const [showConfirmButtons, setsshowConfirmButtons] = useState(false);
    const [addrChangeReason, setAddrChangeReason] = useState('');

    const onCreate = values => {
        console.log('Received values of form: ', values);
        setModalVisible(false);
        setMailingAddress(values);
        if (values.addrChangeReason == 'Other') {
            setAddrChangeReason(values.addrChangeReason + '-' + values.otherReason);
        } else {
            setAddrChangeReason(values.addrChangeReason);
        }
    };
    const [insertItem] = useMutation(insertMutation);
    const callMutation = (cardType) => {
        console.log('callmutation invoked');

        if (!showCardError && !showMailingError) {
            insertItem({
                variables: {
                    address1: displayMailingAddress.address1,
                    address2: displayMailingAddress.address2,
                    city: displayMailingAddress.city,
                    state: displayMailingAddress.state,
                    zip: displayMailingAddress.zip,
                    token: values.token,
                    cardType,
                    ownHome: ownHomeStatus,
                    addrChangeReason
                }
            }).then(
                res => {
                    if (res) {
                        if (res.data.paymentTypeSelection.status) {
                            window.location = 'peguconfirmation?trackingNo=' + res.data.paymentTypeSelection.trackingNo;
                        }
                        //window.location='peguconfirmation';
                    }
                },
                err => { console.log(err); }
            );
        }

        /*mutate({ 
            variables: {
                address1: displayMailingAddress.address1, 
                address2: displayMailingAddress.address2, 
                city: displayMailingAddress.city, 
                state: displayMailingAddress.state, 
                zip: displayMailingAddress.zip, 
                token: values.token,
                cardType
            }
          })
          .then( res => {
            if (res) {
                window.location='peguconfirmation';
            }
            },
            err => {console.log(err); }
        );*/
    }

    const validateSubmission = (cardType) => {
        if (cardType.length === 0) {
            setCardError(true);
        } else {
            setCardError(false);
        }
        if (displayMailingAddress.address1.length === 0 || displayMailingAddress.city.length === 0 || displayMailingAddress.state.length === 0 || displayMailingAddress.zip.length === 0) {
            setMailingError(true);
        } else {
            setMailingError(false);
        }

        if (cardType.length !== 0 && displayMailingAddress.address1.length !== 0 && displayMailingAddress.city.length !== 0 && displayMailingAddress.state.length !== 0 && displayMailingAddress.zip.length !== 0) {
            console.log('in here');
            setsshowConfirmButtons(true);
        }
    }

    const handleClick = clickType => {
        console.log('handle click');
        let cardType = '';
        if (showPhysicalCardSelection) {
            cardType = 'visareward';
        } else if (showVirtualCardSelection) {
            cardType = 'virtualvisa';
        } else if (showCheckSelection) {
            cardType = 'check';
        }
        switch (clickType) {
            case 'continue':
                validateSubmission(cardType);
                break;
            case 'back':
                setsshowConfirmButtons(false);
                break;
            case 'submit':
                setShowSpinner({ display: true });
                callMutation(cardType);
                break;
            default:
                break;
        }
    }

    const paymentSelectionCheck = e => {
        console.log('radio checked', e.target.value);
        setshowVirtualCardSelection(false);
        setshowPhysicalCardSelection(false);
        setshowCheckSelection(false);
        switch (e.target.value) {
            case 'virtualCard':
                setshowVirtualCardSelection(true);
                break;
            case 'physicalCard':
                setshowPhysicalCardSelection(true);
                break;
            case 'physicalCheck':
                setshowCheckSelection(true);
                break;
            default:
                break;

        }
    };

    const changeOwnHomeStatus = (checkStatus) => {
        //console.log(checkStatus);
        setOwnHomeStatus(!checkStatus);
    }

    return (
        <Fragment>
            <LoadingOverlay
                active={showSpinner.display}
                spinner
                text={t('loading_content')}
            >
                <Styles>
                    <main>
                        <Form
                            name="validate_other"
                            {...formItemLayout}
                            onFinish={onFinish}
                        >
                            <div className="container-fluid"><img src="images/SunPower.png" width="180px" alt="SunPower logo" /></div>

                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col align-self-center py-2">
                                        <h2 className="bg-SunPowerBlue p-2 text-white">{t('pegu_payment_form')}</h2>
                                        <p>{t('payment_claim_form')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col">
                                        <h5>{t('account_info')}</h5>
                                        <div className="form-row">
                                            <div className="form-group col-sm">
                                                <label htmlFor="customerNumber" className="small">{t('cus_num')}</label>
                                                <input type="number" className="form-control form-control-sm bg-light border-0" id="customerNumber" placeholder={displayFields.cusLeaseNo} disabled />
                                            </div>

                                            <div className="form-group col-sm">
                                                <label htmlFor="guarantee" className="small">{t('guarantee_yr')}</label>
                                                <input type="number" className="form-control form-control-sm bg-light border-0" id="guarantee" placeholder={displayFields.guaranteeYr} disabled />
                                            </div>
                                            <div className="form-group col-sm">
                                                <label htmlFor="paymentGuaranteeAmount" className="small">{t('pegu_energy_amt')}</label>
                                                <input type="number" className="form-control form-control-sm bg-light border-0" id="paymentGuaranteeAmount" placeholder={displayFields.amount} disabled />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col">
                                        <h5>{t('home_info')}</h5>
                                        <div className="form-row">
                                            <div className="form-group col-sm">
                                                <label htmlFor="fname" className="small">{t('full_name')}</label>
                                                <input type="text" className="form-control form-control-sm form-control-sm bg-light border-0" id="fullName" placeholder={displayFields.fullName} disabled />
                                            </div>
                                            <div className="form-group col-sm">
                                                <label htmlFor="email" className="small">{t('email')}</label>
                                                <input type="email" className="form-control form-control-sm form-control-sm bg-light border-0" id="email" placeholder={displayFields.email} disabled />
                                            </div>
                                            <div className="form-group col-sm">
                                                <label htmlFor="phone" className="small">{t('phone')}</label>
                                                <input type="tel" className="form-control form-control-sm form-control-sm bg-light border-0" id="phone" placeholder={displayFields.phone} disabled />
                                            </div>
                                        </div>
                                        <br />
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md">
                                        <h5>{t('install_address')}</h5>

                                        <div className="form-row">
                                            <div className="form-group col-sm">
                                                <label htmlFor="installAddress1" className="sr-only">{t('address_1')}</label>
                                                <   input type="text" className="form-control form-control-sm bg-light border-0" id="installAddress1" placeholder={displayInstallAddress.address1} disabled />
                                            </div>
                                            <div className="form-group col-sm">
                                                <label htmlFor="installAddress2" className="sr-only">{t('address_2')}</label>
                                                <input type="text" className="form-control form-control-sm bg-light border-0" id="installAddress2" placeholder={displayInstallAddress.address2} disabled />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-sm-6">
                                                <label htmlFor="installCity" className="sr-only">{t('city')}</label>
                                                <input type="text" className="form-control form-control-sm bg-light border-0" id="installCity" placeholder={displayInstallAddress.city} disabled />
                                            </div>
                                            <div className="form-group col-sm-3">
                                                <label htmlFor="installState" className="sr-only">{t('state')}</label>
                                                <select id="installState" className="form-control form-control-sm bg-light border-0" disabled>
                                                    <option value={displayInstallAddress.state}>{displayInstallAddress.state}</option>
                                                </select>

                                            </div>
                                            <div className="form-group col-sm-3">
                                                <label htmlFor="installZip" className="sr-only">{t('zip')}</label>
                                                <input type="text" className="form-control form-control-sm bg-light border-0" id="installZip" placeholder={displayInstallAddress.zip} disabled />
                                            </div>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input bg-light border-0" type="checkbox" value={ownHomeStatus} id="ownHome" checked={ownHomeStatus} onChange={() => changeOwnHomeStatus(ownHomeStatus)} disabled={showConfirmButtons} />
                                            <label className="form-check-label" htmlFor="ownHome">
                                                {t('still_own_this_home')}
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col-md">
                                        <h5>{t('mail_addr')}</h5>
                                        <div className="row" style={{ display: ((showMailingError) ? 'block' : 'none') }}>
                                            <div className="col">
                                                <div className='errorMsg'>{t('add_mail_addr')}</div>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col">
                                                <label htmlFor="address1" className="sr-only">{t('address_1')}</label>
                                                <input type="text" className="form-control form-control-sm bg-light border-0" id="address1" placeholder={displayMailingAddress.address1} disabled />
                                            </div>

                                            <div className="form-group col">
                                                <label htmlFor="address2" className="sr-only">{t('address_2')}</label>
                                                <input type="text" className="form-control form-control-sm bg-light border-0" id="address2" placeholder={displayMailingAddress.address2} disabled />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="city" className="sr-only">{t('city')}</label>
                                                <input type="text" className="form-control form-control-sm bg-light border-0" id="city" placeholder={displayMailingAddress.city} disabled />
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label htmlFor="state" className="sr-only">{t('state')}</label>
                                                <select id="state" className="form-control form-control-sm bg-light border-0" disabled>
                                                    <option value={displayMailingAddress.state}>{displayMailingAddress.state}</option>
                                                </select>
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label htmlFor="inputZip" className="sr-only">{t('zip')}</label>
                                                <input type="text" className="form-control form-control-sm bg-light border-0" id="inputZip" placeholder={displayMailingAddress.zip} disabled />
                                            </div>
                                        </div>
                                        <div className="form-row" style={{ display: ((!showConfirmButtons) ? 'block' : 'none') }}>
                                            <div className="form-group col-md-10">
                                                <Button
                                                    type="primary"
                                                    onClick={() => {
                                                        setModalVisible(true);
                                                    }}
                                                    className='BlueBtnOutline'
                                                >
                                                    {t('change_mail_addr')}
                                                </Button>
                                                <MailingModal
                                                    visible={visibleModal}
                                                    onCreate={onCreate}
                                                    onCancel={() => {
                                                        setModalVisible(false);
                                                    }}
                                                    displayCheckBox={false}
                                                    mailingAddress={displayMailingAddress}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <hr />
                                <div className="row" style={{ display: ((showCardError) ? 'block' : 'none') }}>
                                    <div className="col">
                                        <div className='errorMsg'>{t('select_one_payment_type')}</div>
                                    </div>
                                </div>
                                {window.innerWidth > 800 ?
                                    <div className="row payment-radio" style={{ display: (((!showPhysicalCardSelection && !showVirtualCardSelection && !showCheckSelection) || !showConfirmButtons) ? 'block' : 'none') }}>
                                        <div className="col-12">
                                            <h5>{t('select_payment_type')}:</h5>

                                            <Form.Item name="radio-button" noStyle={true}>

                                                <Radio.Group onChange={paymentSelectionCheck}>

                                                    <Radio.Button value="virtualCard" className="h-100 mr-4" style={{ display: ((showVirtualCardRewardType) ? '' : 'none') }}>
                                                        {t('virtual_visa')}
                                                        <img src="images/SunPower Visa Blue.png" className="d-block py-2" height="80px" alt="virtual" />
                                                    </Radio.Button>

                                                    <Radio.Button value="physicalCard" className="h-100 mr-4"
                                                        style={{ display: ((showPhysicalCardRewardType) ? '' : 'none') }}>
                                                        {t('physical_visa')}
                                                        <img src="images/SunPower Visa Silver.png" className="d-block py-2" height="80px" alt="physical" />
                                                    </Radio.Button>
                                                    <Radio.Button value="physicalCheck" className="h-100 mr-4" style={{ display: ((showCheckRewardType) ? '' : 'none') }}>
                                                        {t('check')}
                                                        <img src="images/SunPower Check.png" className="d-block py-2" height="80px" alt="check" />
                                                    </Radio.Button>
                                                </Radio.Group>

                                            </Form.Item>
                                            <br />
                                            <br />
                                            <br />
                                            <hr />
                                        </div>
                                    </div>
                                    :
                                    <div className="row" style={{ display: (((!showPhysicalCardSelection && !showVirtualCardSelection && !showCheckSelection) || !showConfirmButtons) ? 'block' : 'none') }}>
                                        <div className="col-12">
                                            <h5>{t('select_payment_type')}:</h5>

                                            <Form.Item name="radio-button" label="" noStyle={true}>

                                                <Radio.Group onChange={paymentSelectionCheck} className="w-100 text-center">

                                                    <Radio.Button value="virtualCard" className="w-100 my-2" style={{ display: ((showVirtualCardRewardType) ? '' : 'none') }}>
                                                        {t('virtual_visa')}
                                                    </Radio.Button>

                                                    <Radio.Button value="physicalCard" className="w-100 my-2" style={{ display: ((showPhysicalCardRewardType) ? '' : 'none') }}>
                                                        {t('physical_visa')}
                                                    </Radio.Button>

                                                    <Radio.Button value="physicalCheck" className="w-100 my-2" style={{ display: ((showCheckRewardType) ? '' : 'none') }}>
                                                        {t('check')}
                                                    </Radio.Button>
                                                </Radio.Group>

                                            </Form.Item>

                                            <br />
                                            <br />
                                            <br />
                                            <hr />
                                        </div>
                                    </div>
                                }
                                <div className="row" style={{ display: ((showVirtualCardSelection && showConfirmButtons) ? 'block' : 'none') }}>
                                    <div className="col">
                                        <label className="form-check-label" htmlFor="virtualCard">
                                            <input type="checkbox" name="paymentOptions" id="virtualCard" value="virtualCard" checked disabled /> {t('virtual_visa')}
                                            <img src="images/SunPower Visa Blue.png" className="d-block mx-auto p-2" height="80px" alt="virtual" />
                                        </label>
                                        <br />
                                        <br />
                                        <hr />
                                    </div>
                                </div>
                                <div className="row" style={{ display: ((showPhysicalCardSelection && showConfirmButtons) ? 'block' : 'none') }}>
                                    <div className="col">
                                        <label className="form-check-label" htmlFor="physicalCard">
                                            <input type="checkbox" name="paymentOptions" id="physicalCard" value="physicalCard" checked disabled /> {t('physical_visa')}
                                            <img src="images/SunPower Visa Silver.png" className="d-block mx-auto p-2" height="80px" alt="virtual" />
                                        </label>
                                        <br />
                                        <br />
                                        <hr />
                                    </div>
                                </div>
                                <div className="row" style={{ display: ((showCheckSelection && showConfirmButtons) ? 'block' : 'none') }}>
                                    <div className="col">
                                        <label className="form-check-label" htmlFor="physicalCheck">
                                            <input type="checkbox" name="paymentOptions" id="physicalCheck" value="physicalCheck" checked disabled /> {t('check')}
                                            <img src="images/SunPower Check.png" className="d-block mx-auto p-2" height="80px" alt="virtual" />
                                        </label>
                                        <br />
                                        <br />
                                        <hr />
                                    </div>
                                </div>
                                <div className="row" style={{ display: ((!showConfirmButtons) ? 'block' : 'none') }}>
                                    {window.innerWidth > 800 ?
                                        <div className="col-sm-2 col-md-3 col-lg-3 text-center">
                                            <Button type="primary" shape="round" className="OrangeBtn" size="large" onClick={() => handleClick('continue')} >
                                                {t('continue')}
                                            </Button>
                                            <br />
                                            <br />
                                        </div>
                                        :
                                        <div className="col-sm-2 col-md-3 col-lg-3">
                                            <Button type="primary" shape="round" className="OrangeBtn" size="large" onClick={() => handleClick('continue')} >
                                                {t('continue')}
                                            </Button>
                                            <br />
                                            <br />
                                        </div>
                                    }

                                </div>
                                <div className="row" style={{ display: ((showConfirmButtons) ? 'block' : 'none') }}>
                                    <div className="col-sm-6">&nbsp;</div>
                                    <div className="col-sm-6">
                                        <Button shape="round" className="btn-light" size="large" onClick={() => handleClick('back')} >
                                            {t('back')}
                                        </Button>
                                        <Button type="primary" shape="round" className="OrangeBtn2" size="large" onClick={() => handleClick('submit')} >
                                            {t('submit')}
                                        </Button>

                                    </div>

                                </div>
                                <div className="bg-light p-4">

                                    <p className="small"><span className="font-weight-bold">{t('eligibility_requirements')}:</span> {t('own_home_terms')}</p>

                                    <p className="small"><span className="font-weight-bold">* {t('rebate_terms')}:</span> {t('rebate_terms_text')}</p>

                                    <p className="small"><span className="font-weight-bold">** {t('reward_card')}:</span> {t('reward_card_terms')}</p>

                                </div>
                            </div>
                        </Form>
                    </main>
                    <br />
                    <footer className="bg-light p-2">
                        <div className="container text-center">
                            <div className="row">
                                <div className="col">
                                    <p className="mb-0">{new Date().getFullYear()} SunPower Corporation. All Rights Reserved.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <a href="https://us.sunpower.com/privacy/website-policy" className="BlueLink">{t('privacy_policy')}</a>
                                </div>
                                <div className="col">
                                    <a href="#" className="BlueLink">{t('terms_of_use')}</a>
                                </div>
                            </div>
                        </div>
                    </footer>
                </Styles>
            </LoadingOverlay>
        </Fragment>
    );

}

export default PeguPayment;
//export default graphql(insertMutation)(PeguPayment);