import React, { useState, useCallback, useEffect, Fragment } from "react";

import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/js/src/collapse.js";
import styled from 'styled-components';

import NavigationBar from './NavigationBar';
import Header from './Header';
import Footer from './Footer';
import { useTranslation } from "react-i18next";

const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};
const Styles = styled.div`
.bg-SunPowerBlue {
	background-color: #0076be;
}
.payment-radio {
    margin-left: 150px;
}
.form-check-label {
    text-align: center important!;
}
.ant-radio-button {
    padding: 10px;
}
.OrangeBtn {
    margin-left: 400px;
	font-weight: 500;
	background-color: #F18B21;
	color: hsla(0,0%,100%,1.00);
}
.OrangeBtn:hover {
    
	background-color: #E2620E;
	color: hsla(0,0%,100%,1.00);
}
.OrangeBtn2 {
    font-weight: 500;
	background-color: #F18B21;
	color: hsla(0,0%,100%,1.00);
}
.OrangeBtn2:hover {
	background-color: #E2620E;
	color: hsla(0,0%,100%,1.00);
}
.BlueBtn {
	font-weight: 500;
	background-color: #0076BE;
	color: #FFFFFF;
}

.BlueBtn:hover {
	background-color:#39607A;
	color: #FFFFFF;
}

.BlueBtn:active {
	background-color:#39607A;
	color: #FFFFFF;
}
.BlueBtn:focus {
	background-color:#39607A;
	color: #FFFFFF;
}


.BlueBtnOutline {
	font-weight: 500;
	border: 1px solid #0076BE;
    background-color:#FFFFFF !important;
    color: #0076BE;
}

.BlueBtnOutline:hover {
	background-color:#0076BE !important;
	color: #FFFFFF;
}

.BlueBtnOutline:focus {
	background-color:#39607A !important;
	color: #FFFFFF;
}

.BlueBtnOutline:active {
	background-color:#39607A !important;
	color: #FFFFFF;
}

.BlueRadioBtn:hover {
	border: 1px solid #0076BE;
	color: #0076BE;
}

.BlueRadioBtn.active {
	border: 1px solid #0076BE;
	color: #0076BE;
}

main {
	min-height: 100vh;
	padding-bottom: 90px;
}

footer{
	height: 80px;
	margin-top: -80px;
	width: 100%;
}
.errorMsg {
    color: red;
}

.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

.divider-light {
	background-color: #E0E0E5;
	height: 2px;
	margin-bottom: 14px;
}

.divider-orange {
	background-color: #F18B21;
	height: 3.5px;
	width: 36px;
}

.divider-orange-lg {
	background-color: #F18B21;
	height: 3px;
	width: 100px;
}


.bg-banner1 {
	background-image: url("images/1200x627_banner no logo (1).png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom;
}

.bg-SunPowerBlack {
	background-color: hsla(0,0%,0%,0.60);
	color:#FFFFFF;
	text-align: center;
}



/*Nav*/
.nav-link.active {
	color: #F18B21;
	border-bottom: #F18B21 2px solid;
}

.nav-link:hover {
	color: #F18B21;
	border-bottom: #F18B21 2px solid;
}
/*Nav*/

/*Tabs*/

.nav-link.tab-title {
	color: #858585;
	background-color: #EEEEEE;
}

.nav-link.tab-title:hover {
	color: #E2620E;
}

.nav-link.tab-title.active {
	color: #E2620E;
}

/*Tabs*/


/*Links*/

.OrangeLink {
	color: #F18B21;
}
.OrangeLink:hover {
	color: #E2620E;
}

.BlueLink {
	color: #0076BE;
}

/*Typography*/

h1, h2, h3, h4, h5 {
	font-weight: 500;
}
`;

const Faqs = (props) => {
    const { t } = useTranslation();
    return (
        <Fragment>
            
                <NavigationBar />
                <Header 
                    showTracker={false}
                />
                <Styles>
                    <main>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col">
                                <h2>{t('faqs')}</h2>
                                <div className="divider-orange my-4"></div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
	                    <div className="row justify-content-center">
		                    <div className="col">
                                <p>
                                    <a className="text-body font-weight-bold" data-toggle="collapse" href="#faq1" role="button" aria-expanded="false" aria-controls="faq1"><span className="dropdown-toggle pr-2"></span>{t('cannot_find_tracking_no')}</a>
                                    </p>
                                    <div className="collapse" id="faq1">
                                        <p className="border-bottom pb-2">
                                        {t('refer_confirm_email')} <a href="/contactus" className="OrangeLink">{t('contact_us')}</a> {t('faq_page')}.</p>
                                    </div>
                                    
                                    <p>
                                        <a className="text-body font-weight-bold" data-toggle="collapse" href="#faq2" role="button" aria-expanded="false" aria-controls="faq2"><span className="dropdown-toggle pr-2"></span>{t('will_i_receive_payment')}</a>
                                    </p>
                                    <div className="collapse" id="faq2">
                                        <p className="border-bottom pb-2">
                                            {t('weeks_to_receive_sunpower_payment')}
                                        </p>
                                    </div>
                                    
                                    <p>
                                        <a className="text-body font-weight-bold" data-toggle="collapse" href="#faq3" role="button" aria-expanded="false" aria-controls="faq3"><span className="dropdown-toggle pr-2"></span>{t('switch_my_payment_type')}</a>
                                    </p>
                                    <div className="collapse" id="faq3">
                                        <p className="border-bottom pb-2">
                                        {t('faq3_answer')}
                                        </p>
                                    </div>
                                    
                                    <p>
                                        <a className="text-body font-weight-bold" data-toggle="collapse" href="#faq4" role="button" aria-expanded="false" aria-controls="faq4"><span className="dropdown-toggle pr-2"></span>{t('payment_options_provided')}</a>
                                    </p>
                                    <div className="collapse" id="faq4">
                                        <p className="border-bottom pb-2">
                                        {t('contact_cus_service')} <a href="/contactus" className="OrangeLink">{t('contact_us')}</a> {t('faq_page')}.
                                        </p>
                                    </div>

                                    <p>
                                        <a className="text-body font-weight-bold" data-toggle="collapse" href="#faq5" role="button" aria-expanded="false" aria-controls="faq5"><span className="dropdown-toggle pr-2"></span>{t('need_to_change_mail_addr')}</a>
                                    </p>
                                    <div className="collapse" id="faq5">
                                        <p className="border-bottom pb-2">
                                        {t('contact_cus_service')} <a href="/contactus" className="OrangeLink">{t('contact_us')}</a> {t('faq_page')}.
                                        </p>
                                    </div>
                                    
                                    <p>
                                        <a className="text-body font-weight-bold" data-toggle="collapse" href="#faq6" role="button" aria-expanded="false" aria-controls="faq6"><span className="dropdown-toggle pr-2"></span>{t('link_in_email_does_not_work')}</a>
                                    </p>
                                    <div className="collapse" id="faq6">
                                        <p className="border-bottom pb-2">
                                        {t('contact_cus_service')} <a href="/contactus" className="OrangeLink">{t('contact_us')}</a> {t('faq_page')}.
                                        </p>
                                    </div>
                                    
                                    <p>
                                        <a className="text-body font-weight-bold" data-toggle="collapse" href="#faq7" role="button" aria-expanded="false" aria-controls="faq7"><span className="dropdown-toggle pr-2"></span>{t('does_my_card_expire')}</a>
                                    </p>
                                    <div className="collapse" id="faq7">
                                        <p className="border-bottom pb-2">
                                        {t('faq7_answer')}
                                        </p>
                                    </div>
                                    
                                    <p>
                                        <a className="text-body font-weight-bold" data-toggle="collapse" href="#faq8" role="button" aria-expanded="false" aria-controls="faq8"><span className="dropdown-toggle pr-2"></span>{t('never_received_check')}</a>
                                    </p>
                                    <div className="collapse" id="faq8">
                                        <p className="border-bottom pb-2">
                                        {t('contact_cus_service')} <a href="/contactus" className="OrangeLink">{t('contact_us')}</a> {t('faq_page')}.
                                        </p>
                                    </div>
                                    
                                    <p>
                                        <a className="text-body font-weight-bold" data-toggle="collapse" href="#faq9" role="button" aria-expanded="false" aria-controls="faq9"><span className="dropdown-toggle pr-2"></span>{t('card_expired_ques')}</a>
                                    </p>
                                    <div className="collapse" id="faq9">
                                        <p className="border-bottom pb-2">
                                        {t('request_remaining_funds')} <span className="font-weight-bold">800-409-8960</span> {t('putting_request_through')} <a href="/contactus" className="OrangeLink">{t('contact_us')}</a> {t('faq_page')}. {t('fees_and_restrictions')}
                                        </p>
                                    </div>
                                    <p>
                                        <a className="text-body font-weight-bold" data-toggle="collapse" href="#faq10" role="button" aria-expanded="false" aria-controls="faq10"><span className="dropdown-toggle pr-2"></span>{t('different_status_track_payment')}</a>
                                    </p>
                                    <div className="collapse" id="faq10">
                                        <p className="border-bottom pb-2">
                                        {t('status_and_meaning')}:
                                        <ol type="1">
                                            <li>{t('pending_status_mean')}</li>
                                            <li>{t('processing_status_mean')}</li>
                                            <li>{t('shipped_status_mean')}</li>
                                        </ol>
                                        </p>
                                        
                                    </div>
                                    <p>
                                        <a className="text-body font-weight-bold" data-toggle="collapse" href="#faq11" role="button" aria-expanded="false" aria-controls="faq11"><span className="dropdown-toggle pr-2"></span>{t('not_in_agreement_pegu')} </a>
                                    </p>
                                    <div className="collapse" id="faq11">
                                        <p className="border-bottom pb-2">
                                        {t('faq11_main_bullet')}:
                                        <ul>
                                            <li>{t('faq11_bullet1')}</li>
                                            <li>{t('faq11_bullet2')}</li>
                                            <li>{t('faq11_bullet3')}</li>
                                            <li>{t('faq11_bullet4')}</li>
                                        </ul>
                                        {t('faq11_footer')}
                                        </p>
                                    </div>

                                    <p>
                                        <a className="text-body font-weight-bold" data-toggle="collapse" href="#faq12" role="button" aria-expanded="false" aria-controls="faq12"><span className="dropdown-toggle pr-2"></span>{t('annual_pegu_info_question')}  </a>
                                    </p>
                                    <div className="collapse" id="faq12">
                                        <p className="border-bottom pb-2">
                                        {t('faq12_answer')}
                                         </p>
                                    </div>

                                    <p>
                                        <a className="text-body font-weight-bold" data-toggle="collapse" href="#faq13" role="button" aria-expanded="false" aria-controls="faq13"><span className="dropdown-toggle pr-2"></span> {t('customer_satisfaction_question')}</a>
                                    </p>
                                    <div className="collapse" id="faq13">
                                        <p className="border-bottom pb-2">
                                        {t('faq13_answer_part1')} 
                                        <br />
                                        {t('faq13_answer_part2')}
                                        </p>
                                    </div>

                                    <p>
                                        <a className="text-body font-weight-bold" data-toggle="collapse" href="#faq14" role="button" aria-expanded="false" aria-controls="faq14"><span className="dropdown-toggle pr-2"></span>{t('faq14_question')} </a>
                                    </p>
                                    <div className="collapse" id="faq14">
                                        <p className="border-bottom pb-2">
                                        {t('faq14_answer_part1')}
                                        {t('faq14_answer_part2')} 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </Styles>
                <Footer />
            
        </Fragment>
        
    );
}

export default Faqs;