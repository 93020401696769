export const SystemStatus = {
    PENDING: 'PENDING',
    FAILED: 'FAILED',
    PROCESSED: 'PROCESSED',
    SHIPPED: 'SHIPPED',
    PROCESSING: 'PROCESSING',
    REDEMMED: 'REDEEMED',
    VOID: 'VOID',
    AUTO_REDEEMED: 'AUTO_REDEEMED',
    REDEEMED_FOR_CHECK: 'REDEEMED_FOR_CHECK',
    EXPIRED: 'EXPIRED'
}

export const RewardTypes = {
    PHYSICAL: 'visareward',
    VIRTUAL: 'virtualvisa',
    CHECK: 'check'
}