import React from 'react';
import { Jumbotron as Jumbo, Container,  Row,Col } from 'react-bootstrap';
import styled from 'styled-components';
import LanguageSelect from './LanguageSelect';

const Styles = styled.div`
.bg-bannerHeader {
	background-image: url("images/1200x627_banner.png");
	background-repeat: no-repeat;
	background-size: cover;
    background-position: bottom;
    height: 300px;
    margin-bottom: 14px;
}

.dividerheader-light {
	background-color: #E0E0E5;
	height: 2px;
	margin-bottom: 14px;
}

.language-select {
	float: right;
	margin-top: -55px;
	margin-right: 250px;
}

.dividerheader-orange {
	background-color: #F18B21;
	height: 3.5px;
	width: 36px;
}

.dividerheader-orange-lg {
	background-color: #F18B21;
	height: 3px;
	width: 100px;
}

`;

const Header = (showTracker) => {
    return (
        <Styles>
			<div className="language-select">
        		<LanguageSelect />
    		</div>
            <Container className="dividerheader-light"></Container>
            <Container className="bg-bannerHeader">
            </Container>
        </Styles>
    );
  
}
export default Header;